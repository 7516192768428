:root {

    --light-pink: #EDC7DB;
    --dark-pink: #C54B8C;
}

.heading {

    color: white;
    font-size: 4rem !important;
    font-weight: 700 !important;
}

.pricing-list{

    background-color: white;
    border-radius: 30px;
    padding: 5px;
}

.pricing-tab{
    z-index: 1;
    transition: all 0.3s ease-in-out;
}

.pricing-tab:focus{
    color: white;
}

.get-start-btn1 {
    background-color: var(--light-pink) !important;
    color: var(--dark-pink) !important;
  }

  .get-start-btn2 {
    background-color: var(--dark-pink) !important;
    color: white;
  }
