:root {

    --light-pink: #EDC7DB;
    --dark-pink: #C54B8C;
}

.home {
    padding: 3rem 5rem !important;
    color: #f7e8fe !important;
    overflow-x: hidden !important;
}

.home-hero {
    text-align: center !important;
    margin-top: 7rem !important;
    position: relative;
}

.heading {
    font-size: 6.4rem !important;
    font-weight: 700 !important;
}

.hero-subheading {
    font-size: 2.4rem !important;
    font-weight: 700 !important;
}

.try-it-btn {
    background-color: var(--dark-pink) !important;
    color: var(--light-pink) !important;
    max-width: fit-content !important;
    padding: 1rem 3rem !important;
}

.btn-below-text {
    font-size: 1.6rem !important;
    font-weight: 400 !important;
    margin-top: 2rem !important;
}

.gif1 {
    width: 22rem !important;
    position: absolute;
    top: 0;
    right: 3rem;
}

.gif2 {
    width: 22rem !important;
    position: absolute;
    bottom: 0;
    left: 7rem;
}

.frame-img {
    width: 100%
}

.home-video {
    width: 100%;
    border-radius: 2rem;
}

.single-step {
    margin-bottom: 5rem !important;
}

.step-section {
    padding: 10rem 30rem;
    position: relative;
}

.step-text {
    font-size: 1.7rem !important;
}

.step-bold-text {
    font-size: 2.5rem !important;
    font-weight: 600 !important;
}

.small-left-circle {
    border: 1px solid #d88ab3 !important;
    height: 10px !important;
    width: 10px !important;
    border-radius: 50% !important;
    position: absolute;
    top: 20vh;
    left: 10rem;
}

.small-right-circle {
    border: 1px solid #d88ab3 !important;
    height: 10px !important;
    width: 10px !important;
    border-radius: 50% !important;
    position: absolute;
    bottom: 20vh;
    right: 10rem;
}

.large-left-circle {
    border: 1px solid #d88ab3 !important;
    height: 150px !important;
    width: 150px !important;
    border-radius: 50% !important;
    position: absolute;
    top: 30vh;
    left: -10rem;
}

.large-right-circle {
    border: 1px solid #d88ab3 !important;
    height: 150px !important;
    width: 150px !important;
    border-radius: 50% !important;
    position: absolute;
    bottom: 0;
    right: -13rem;
}

.large-left-video-circle {
    border: 1px solid #d88ab3 !important;
    height: 150px !important;
    width: 150px !important;
    border-radius: 50% !important;
    position: absolute;
    left: -5rem;
}

.small-right-video-circle {
    border: 1px solid #d88ab3 !important;
    height: 10px !important;
    width: 10px !important;
    border-radius: 50% !important;
    position: absolute;
    ;
    right: 2rem;
}

.home-video-section {
    position: relative;
}

.home-video-content {
    width: 90%;
    text-align: center;
    gap: 3rem;
    display: grid;
    position: relative;
}

.video-btn {
    position: absolute;
    bottom: 10rem;
    justify-self: center;
    background-color: var(--dark-pink) !important;
    color: var(--light-pink) !important;
    max-width: fit-content !important;
    padding: 1rem 3rem !important;
}

.faq {
    /* width: 100vw !important; */
    padding: 5rem 8rem !important;
    background-color: rgba(255, 255, 255, 0.103) !important;
}

.faq-q {
    font-size: 3rem !important;
    font-weight: 700 !important;
}

.faq-ans {
    font-size: 1.8rem !important;
    font-weight: 500 !important;
}

@media (max-width: 600px) {
    .home {
        padding: 3rem 2rem 10rem !important;
    }

    .home-hero {
        margin-top: 0rem !important;
    }

    .gif1 {
        width: 15rem !important;
        top: 10rem;
        right: 0;
    }

    .gif2 {
        width: 15rem !important;
        bottom: 10rem;
    }

    .step-section {
        padding: 5rem;
    }

    .video-btn {
        bottom: 7rem;
        padding: 0.5rem !important;
    }

    .heading {
        font-size: 4rem !important;
    }

    .large-right-circle {
        right: -18rem;
    }

    .small-right-circle {
        bottom: 30vh;
        right: 5rem;
    }

    .small-left-circle {
        left: 7rem;
    }

    .large-left-video-circle {
        left: -12rem;
    }
}

@media only screen and (max-width: 1200px) and (min-width: 600px) {
    .gif1 {
        right: 0;
    }

    .step-section {
        padding: 10rem 10rem;
    }
}