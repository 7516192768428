:root {

  --light-pink: #EDC7DB;
  --dark-pink: #C54B8C;
}


.header {
  padding: 1rem 5rem !important;
  color: white !important;
  position: sticky;
  top: 0;
  transition: all 0.5s ease;
  z-index: 10;
}

img {
  width: 8rem;
}

.brand-name{
  font-size: 3.6rem !important;
  font-weight: 700 !important;
  cursor: pointer !important;
  margin-top: 1rem !important;
}

.nav-item{
  font-size: 1.8rem !important;
  font-weight: 600 !important;
  cursor: pointer !important;
}

.get-start-btn {
  background-color: var(--light-pink) !important;
  color: var(--dark-pink) !important;
}

.scrolled {
  background: #873E96 !important;
}


.nav-links{
  text-decoration: none;
  color: white;
}


.nav-links-sm{
  text-decoration: none;
  color: #424242;
}

@media (max-width: 600px) {
  .header {
    padding: 3rem 2rem !important;
  }
}

