html,
body {
  height: 100%;
  font-size: 62.5%;
  background: rgb(53, 0, 136);
  background: linear-gradient(180deg, rgba(53, 0, 136, 1) 25%, rgba(135, 62, 150, 1) 72%);
  background-attachment: fixed;
}

@media (min-width: 768px) and (max-width: 1200px) {
  html {
    font-size: 50%;
  }
}

@media (max-width: 768px) {
  html {
    font-size: 40%;
  }
}

.MuiButton-contained {
  text-transform: none !important;
  font-size: 1.5rem !important;
  border-radius: 2rem !important;
  font-weight: 600 !important;
}