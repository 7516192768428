:root {
  --dark: rgb(15, 15, 15);
}

.footer {
  background: rgb(153, 116, 153);
  background: linear-gradient(180deg, rgba(153, 116, 153, 1) 0%, rgba(135, 62, 150, 1) 65%);
  color: white !important;
  padding: 3rem 5rem !important;
}

.social-icon {
  font-size: 4rem !important;
  cursor: pointer !important;
}

.footer-text {
  font-size: 1.8rem !important;
}

@media (max-width: 600px) {
  .footer {
    padding: 3rem 2rem !important;
  }
}